.gallery {
  width: 100vw;
  grid-column: 1/3;
  grid-row: 1/3;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: url('/images/cursor-next.png'), auto;
  background-color: #222;
  opacity: .95;
}

.gallery.left {
  cursor: url('/images/cursor-previous.png'), auto;
}

.gallery-work {
  max-height: 100%;
  max-width: 100%;
}
