.art-nav {
  grid-column: 2;
  grid-row: 1/4;
  overflow: scroll;
  padding: 10vh 10vw;
}

.artwork {
  float:right;
  transform-style: preserve-3d;
  transform-origin: right center;
  transform: rotate3d(0, 1, 0, -35deg) scale(1) translateZ(0);
  transition: all .3s linear;
  max-width: 40vw;
  /* max-height: 60vh; */
  margin: 2vh 1vw;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.art-container {
  width: 100%;
  cursor: pointer;
  padding: 2vh 5vw;
  transform-style: preserve-3d;
  transform-origin: right center;
  transform: perspective(300vw);
  animation: upAndSpin 1.2s;
}

.artwork:hover {
  transform: rotate3d(0, 1, 0, -35deg) scale(1.1) translateZ(0);
  z-index: 3000;
}

@keyframes upAndSpin {
    from {transform: translateY(800px) perspective(0vw) rotate3d(0, 1, 0, -45deg)}
    to {transform: translateY(0px) perspective(300vw) rotate3d(0, 1, 0, 0deg)}
}

@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
  .artwork {
    width: 65vw;
  }
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px){
  .artwork {
    width: 50vw;
  }
}
