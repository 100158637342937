@import url(https://fonts.googleapis.com/css?family=Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Major+Mono+Display);
@import url(https://fonts.googleapis.com/css?family=Staatliches);
.display-info {
  color: #1D1E2B;
  grid-column: 1;
  grid-row: 2;
  padding: 4vh 4vw;
}

.display-info h1, h2 {
  font-family: 'Major Mono Display';
}

.display-info h1 {
  font-size: 4vw;
}

.display-info h2 {
  font-size: 3vw;
}

.display-info h3 {
  font-size: 3vw;
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
  .display-info {
    grid-column: 1/2;
  }

  .display-info h1 {
    font-size: 8vw;
  }

  .display-info h2 {
    font-size: 6vw;
  }

  .display-info h3 {
    font-size: 5vw;
  }
}

/* iPads and tablets (portrait and landscape) ----------- */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px){
  .display-info {
    grid-column: 1/2;
  }

  .display-info h1 {
    font-size: 8vw;
  }

  .display-info h2 {
    font-size: 6vw;
  }

  .display-info h3 {
    font-size: 5vw;
  }
}

.music-nav {
  grid-column: 2;
  grid-row: 1/4;
  overflow: scroll;
  z-index: 3000;
  -webkit-perspective: 120vw;
          perspective: 120vw;
  -webkit-transform-origin: left top;
          transform-origin: left top;
  padding-right: 4vw;
}

.song-title {
  font-family: 'Staatliches', serif;
  font-size: 8vw;
  font-weight: bolder;
  font-stretch: expanded;
  margin: 1vw 0;
  text-align: right;
  padding-right: 4vw;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  -webkit-transform-origin: right center;
          transform-origin: right center;
  -webkit-transform: rotate3d(0, 1, 0, -35deg) scale(1) translateZ(0);
          transform: rotate3d(0, 1, 0, -35deg) scale(1) translateZ(0);
  -webkit-transition: all .3s linear;
  transition: all .3s linear;
  width: 100%;

}

.song-container {
  width: 100%;
  cursor: pointer;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  /* transform-origin: right center;
  transform: perspective(250vw); */
  -webkit-animation: upAndSpin 1.2s;
          animation: upAndSpin 1.2s;
  -webkit-transform: translateY(0px) perspective(300vw) rotate3d(0, 1, 0, 0deg);
          transform: translateY(0px) perspective(300vw) rotate3d(0, 1, 0, 0deg)
}

.song-title:hover {
  -webkit-transform: rotate3d(0, 1, 0, -25deg) scale(1.1) translateZ(0);
          transform: rotate3d(0, 1, 0, -25deg) scale(1.1) translateZ(0);
  color: #F5E3E0;
}

.media-link {
  color: #1D1E2B;
  text-decoration: none;
}

.media-link:hover {
  color: #F5E3E0;
  text-decoration: underline;
}

@-webkit-keyframes upAndSpin {
    from {-webkit-transform: translateY(800px) perspective(0vw) rotate3d(0, 1, 0, -45deg);transform: translateY(800px) perspective(0vw) rotate3d(0, 1, 0, -45deg)}
    to {-webkit-transform: translateY(0px) perspective(300vw) rotate3d(0, 1, 0, 0deg);transform: translateY(0px) perspective(300vw) rotate3d(0, 1, 0, 0deg)}
}

@keyframes upAndSpin {
    from {-webkit-transform: translateY(800px) perspective(0vw) rotate3d(0, 1, 0, -45deg);transform: translateY(800px) perspective(0vw) rotate3d(0, 1, 0, -45deg)}
    to {-webkit-transform: translateY(0px) perspective(300vw) rotate3d(0, 1, 0, 0deg);transform: translateY(0px) perspective(300vw) rotate3d(0, 1, 0, 0deg)}
}

.button-control {
  display: flex;
  flex-direction: row;
  grid-column: 1/3;
  grid-row: 3;
  background-color: #F5E3E0;
  width: 96vw;
  height: 8vh;
  padding-left: 4vw;
  box-shadow: 0 -2px 1px 0 rgba(0, 0, 0, 0.2), 0 -4px 1px 0 rgba(0, 0, 0, 0.18);
  z-index: 1;
  align-items: center;
}

.song-controls {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.button {
  width: 1.5rem;
  cursor: pointer;
}

.buttons {
  display: flex;
  margin: 0 2vw;
  height: 8vh;
}

.progress-bar {
  display: flex;
  align-items: center;
}

.nav-link {
  color: #1D1E2B;
  margin-right: 2vw;
  text-decoration: none;
  -webkit-transition: color 4s linear, -webkit-transform .2s linear;
  transition: color 4s linear, -webkit-transform .2s linear;
  transition: color 4s linear, transform .2s linear;
  transition: color 4s linear, transform .2s linear, -webkit-transform .2s linear;
}

.nav-link:hover {
  text-decoration: underline;
  -webkit-transform: translateX(.2vw);
          transform: translateX(.2vw);
}

.active {
  text-decoration: underline;
}

.nav-link.pink:hover, .pink.active {
  color: #f953c6;
}

.nav-link.orange:hover, .orange.active {
  color: #F7971E;
}

.nav-link.red:hover, .red.active {
  color: #ED213A;
}

.nav-link.purple:hover, .purple.active {
  color: #cc2b5e;
}

.nav-link.indigo:hover, .indigo.active {
  color: #4e54c8;
}

.nav-link.cyan:hover, .cyan.active {
  color: #2193b0;
}

.nav-link.green:hover, .green.active {
  color: #56ab2f;
}

.nav-link.yellow:hover, .yellow.active {
  color: #f7ff00;
}

@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
  .button-control h3, .prgoress-bar {
    font-size: 4vw;
  }

  .button-control {
    padding-left: 6vw;
    width: 94vw;
    height: 8vh;
  }

  .song-controls {
    z-index: 4000;
  }
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px){
  .button-control h3, .prgoress-bar {
    font-size: 4vw;
  }
}

.music-player {
  grid-column: 1/3;
  grid-row: 3;
}

.art-nav {
  grid-column: 2;
  grid-row: 1/4;
  overflow: scroll;
  padding: 10vh 10vw;
}

.artwork {
  float:right;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  -webkit-transform-origin: right center;
          transform-origin: right center;
  -webkit-transform: rotate3d(0, 1, 0, -35deg) scale(1) translateZ(0);
          transform: rotate3d(0, 1, 0, -35deg) scale(1) translateZ(0);
  -webkit-transition: all .3s linear;
  transition: all .3s linear;
  max-width: 40vw;
  /* max-height: 60vh; */
  margin: 2vh 1vw;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.art-container {
  width: 100%;
  cursor: pointer;
  padding: 2vh 5vw;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  -webkit-transform-origin: right center;
          transform-origin: right center;
  -webkit-transform: perspective(300vw);
          transform: perspective(300vw);
  -webkit-animation: upAndSpin 1.2s;
          animation: upAndSpin 1.2s;
}

.artwork:hover {
  -webkit-transform: rotate3d(0, 1, 0, -35deg) scale(1.1) translateZ(0);
          transform: rotate3d(0, 1, 0, -35deg) scale(1.1) translateZ(0);
  z-index: 3000;
}

@-webkit-keyframes upAndSpin {
    from {-webkit-transform: translateY(800px) perspective(0vw) rotate3d(0, 1, 0, -45deg);transform: translateY(800px) perspective(0vw) rotate3d(0, 1, 0, -45deg)}
    to {-webkit-transform: translateY(0px) perspective(300vw) rotate3d(0, 1, 0, 0deg);transform: translateY(0px) perspective(300vw) rotate3d(0, 1, 0, 0deg)}
}

@keyframes upAndSpin {
    from {-webkit-transform: translateY(800px) perspective(0vw) rotate3d(0, 1, 0, -45deg);transform: translateY(800px) perspective(0vw) rotate3d(0, 1, 0, -45deg)}
    to {-webkit-transform: translateY(0px) perspective(300vw) rotate3d(0, 1, 0, 0deg);transform: translateY(0px) perspective(300vw) rotate3d(0, 1, 0, 0deg)}
}

@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
  .artwork {
    width: 65vw;
  }
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px){
  .artwork {
    width: 50vw;
  }
}

.gallery {
  width: 100vw;
  grid-column: 1/3;
  grid-row: 1/3;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: url('/images/cursor-next.png'), auto;
  background-color: #222;
  opacity: .95;
}

.gallery.left {
  cursor: url('/images/cursor-previous.png'), auto;
}

.gallery-work {
  max-height: 100%;
  max-width: 100%;
}

body {
  font-family: 'Staatliches', serif;
  margin: 0;
  color: #1D1E2B;
}

  /* const colors = ['pink', 'orange', 'red', 'purple', 'indigo', 'cyan', 'green', 'yellow'] */

.App {
  display: grid;
  grid-template-columns: 40vw 60vw;
  grid-template-rows: 32vh 60vh 8vh;
  -webkit-transition: background 3.5s linear;
  transition: background 3.5s linear;
}

.App.pink {
  background: #f953c6;  /* fallback for old browsers */
}

.App.orange {
  background: #F7971E;  /* fallback for old browsers */
}

.App.red {
  background: #ED213A;  /* fallback for old browsers */
}

.App.purple {
  background: #cc2b5e;  /* fallback for old browsers */
}

.App.indigo {
  background: #4e54c8;  /* fallback for old browsers */
}

.App.cyan {
  background: #2193b0;  /* fallback for old browsers */
}

.App.green {
  background: #56ab2f;
}

.App.yellow {
  background: #f7ff00;  /* fallback for old browsers */
}


@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
  .App {
    grid-template-rows: 30vh 50vh 8vh;
    /* padding-bottom: 7vh; */
  }
}

