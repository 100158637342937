.music-nav {
  grid-column: 2;
  grid-row: 1/4;
  overflow: scroll;
  z-index: 3000;
  perspective: 120vw;
  transform-origin: left top;
  padding-right: 4vw;
}

.song-title {
  font-family: 'Staatliches', serif;
  font-size: 8vw;
  font-weight: bolder;
  font-stretch: expanded;
  margin: 1vw 0;
  text-align: right;
  padding-right: 4vw;
  transform-style: preserve-3d;
  transform-origin: right center;
  transform: rotate3d(0, 1, 0, -35deg) scale(1) translateZ(0);
  transition: all .3s linear;
  width: 100%;

}

.song-container {
  width: 100%;
  cursor: pointer;
  transform-style: preserve-3d;
  /* transform-origin: right center;
  transform: perspective(250vw); */
  animation: upAndSpin 1.2s;
  transform: translateY(0px) perspective(300vw) rotate3d(0, 1, 0, 0deg)
}

.song-title:hover {
  transform: rotate3d(0, 1, 0, -25deg) scale(1.1) translateZ(0);
  color: #F5E3E0;
}

.media-link {
  color: #1D1E2B;
  text-decoration: none;
}

.media-link:hover {
  color: #F5E3E0;
  text-decoration: underline;
}

@keyframes upAndSpin {
    from {transform: translateY(800px) perspective(0vw) rotate3d(0, 1, 0, -45deg)}
    to {transform: translateY(0px) perspective(300vw) rotate3d(0, 1, 0, 0deg)}
}
