.display-info {
  color: #1D1E2B;
  grid-column: 1;
  grid-row: 2;
  padding: 4vh 4vw;
}

.display-info h1, h2 {
  font-family: 'Major Mono Display';
}

.display-info h1 {
  font-size: 4vw;
}

.display-info h2 {
  font-size: 3vw;
}

.display-info h3 {
  font-size: 3vw;
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
  .display-info {
    grid-column: 1/2;
  }

  .display-info h1 {
    font-size: 8vw;
  }

  .display-info h2 {
    font-size: 6vw;
  }

  .display-info h3 {
    font-size: 5vw;
  }
}

/* iPads and tablets (portrait and landscape) ----------- */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px){
  .display-info {
    grid-column: 1/2;
  }

  .display-info h1 {
    font-size: 8vw;
  }

  .display-info h2 {
    font-size: 6vw;
  }

  .display-info h3 {
    font-size: 5vw;
  }
}
