.button-control {
  display: flex;
  flex-direction: row;
  grid-column: 1/3;
  grid-row: 3;
  background-color: #F5E3E0;
  width: 96vw;
  height: 8vh;
  padding-left: 4vw;
  box-shadow: 0 -2px 1px 0 rgba(0, 0, 0, 0.2), 0 -4px 1px 0 rgba(0, 0, 0, 0.18);
  z-index: 1;
  align-items: center;
}

.song-controls {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.button {
  width: 1.5rem;
  cursor: pointer;
}

.buttons {
  display: flex;
  margin: 0 2vw;
  height: 8vh;
}

.progress-bar {
  display: flex;
  align-items: center;
}

.nav-link {
  color: #1D1E2B;
  margin-right: 2vw;
  text-decoration: none;
  transition: color 4s linear, transform .2s linear;
}

.nav-link:hover {
  text-decoration: underline;
  transform: translateX(.2vw);
}

.active {
  text-decoration: underline;
}

.nav-link.pink:hover, .pink.active {
  color: #f953c6;
}

.nav-link.orange:hover, .orange.active {
  color: #F7971E;
}

.nav-link.red:hover, .red.active {
  color: #ED213A;
}

.nav-link.purple:hover, .purple.active {
  color: #cc2b5e;
}

.nav-link.indigo:hover, .indigo.active {
  color: #4e54c8;
}

.nav-link.cyan:hover, .cyan.active {
  color: #2193b0;
}

.nav-link.green:hover, .green.active {
  color: #56ab2f;
}

.nav-link.yellow:hover, .yellow.active {
  color: #f7ff00;
}

@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
  .button-control h3, .prgoress-bar {
    font-size: 4vw;
  }

  .button-control {
    padding-left: 6vw;
    width: 94vw;
    height: 8vh;
  }

  .song-controls {
    z-index: 4000;
  }
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px){
  .button-control h3, .prgoress-bar {
    font-size: 4vw;
  }
}
