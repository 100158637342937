@import url('https://fonts.googleapis.com/css?family=Open+Sans');
@import url('https://fonts.googleapis.com/css?family=Major+Mono+Display');
@import url('https://fonts.googleapis.com/css?family=Staatliches');

body {
  font-family: 'Staatliches', serif;
  margin: 0;
  color: #1D1E2B;
}

  /* const colors = ['pink', 'orange', 'red', 'purple', 'indigo', 'cyan', 'green', 'yellow'] */

.App {
  display: grid;
  grid-template-columns: 40vw 60vw;
  grid-template-rows: 32vh 60vh 8vh;
  transition: background 3.5s linear;
}

.App.pink {
  background: #f953c6;  /* fallback for old browsers */
}

.App.orange {
  background: #F7971E;  /* fallback for old browsers */
}

.App.red {
  background: #ED213A;  /* fallback for old browsers */
}

.App.purple {
  background: #cc2b5e;  /* fallback for old browsers */
}

.App.indigo {
  background: #4e54c8;  /* fallback for old browsers */
}

.App.cyan {
  background: #2193b0;  /* fallback for old browsers */
}

.App.green {
  background: #56ab2f;
}

.App.yellow {
  background: #f7ff00;  /* fallback for old browsers */
}


@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
  .App {
    grid-template-rows: 30vh 50vh 8vh;
    /* padding-bottom: 7vh; */
  }
}
